// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-app-template-js": () => import("../src/templates/app-template.js" /* webpackChunkName: "component---src-templates-app-template-js" */),
  "component---src-templates-user-template-js": () => import("../src/templates/user-template.js" /* webpackChunkName: "component---src-templates-user-template-js" */),
  "component---src-templates-tx-template-js": () => import("../src/templates/tx-template.js" /* webpackChunkName: "component---src-templates-tx-template-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

